import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Button } from "@mui/material";
import { Print } from "@mui/icons-material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { SignInButton } from "../ui-components/SignInButton";
import WaterlineLogo from "../assets/images/waterline-logo.png";
import { Box, Stack, textAlign } from "@mui/system";
import { ClientAuthError } from "@azure/msal-browser";

export function Home() {
  return (
    <>
      <AuthenticatedTemplate>
        <Box sx={{ marginTop: 4 }}>
          {/* <Paper elevation={3} sx={{ mt: 4, padding: 4 }}> */}
            <h3 sx={{  }}>Despatch</h3>
          <ButtonGroup orientation="vertical">            
            <Button
              endIcon={<Print />}
              component={RouterLink}
              to="/deliveryPaperwork"
              variant="contained"
              color="primary"
            >
              Delivery Paperwork
            </Button>
            {/* <Button component={RouterLink} to="/adviceNotes" variant="contained" color="primary">Get Advice Notes</Button> */}
          </ButtonGroup>
          {/* </Paper> */}
          
        </Box>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Stack
          spacing={1}
          direction="column"
          alignItems="center"
          sx={{ marginTop: 4 }}
        >
          <img alt="Waterline logo" src={WaterlineLogo} width={120} />
          <Typography variant="h6" sx={{ pb: 4, pt: 2 }}>
            Please sign-in to access despatch paperwork.
          </Typography>
          <SignInButton />
        </Stack>
      </UnauthenticatedTemplate>
    </>
  );
}
