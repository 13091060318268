import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";

import { despatchFunctionsClientConfig } from "./despatchFunctionsConfig";

export async function generateAdviceNotesByShipDate(
  requestedShipDate, locationId,
  accessToken
) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    despatchFunctionsClientConfig.despatchFunctionClientEndpoint + "/api/GenerateAdviceNotesByShipDate_HttpStart/" +
      requestedShipDate + "/" + locationId + "?code=" + despatchFunctionsClientConfig.despatchFunctionKey,
    options
  ).then((response) => response.json());
}

export async function monitorGenerateAdviceNotesByShipDate(statusQueryGetUri) {
  const headers = new Headers();
  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(statusQueryGetUri, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
