import React, { useEffect, useState } from "react";
import { useInterval } from "../utils/useInterval";
import { monitorGenerateAdviceNotesByShipDate } from "../utils/despatchFunctionsClient";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

export function PollingComponent({ statusQueryGetUri, handlePollingComplete }) {
  const [pollingInterval, setPollingInterval] = useState(30000);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setPollingInterval(5000);
    setLoading(true);
  }, [statusQueryGetUri]);

  useInterval(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await monitorGenerateAdviceNotesByShipDate(
          statusQueryGetUri
        );
        // if (!response.ok) {
        //     debugger;
        //   throw new Error('Network response was not ok');
        // }

        //setData(response);
        setError(null);

        if (response.runtimeStatus === "Completed") {
          handlePollingComplete(response);
          setPollingInterval(null); // Stop polling when the page is not visible
          setLoading(false); //only want to stop polling when it's done the business
        }
      } catch (error) {
        setError(error.message);

        setLoading(false);
      }
    }

    fetchData();
  }, pollingInterval);

  return loading ? (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  ) : error ? (
    <div>Error: {error}</div>
  ) : null;
  // <div>
  //   {/* Render the fetched data here */}
  //   <pre>{JSON.stringify(data, null, 2)}</pre>
  // </div>
}

// export default PollingComponent;
