import React, { useEffect, useState } from "react";

// Msal imports
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
// import { callMsGraph } from "../utils/MsGraphApiCall";
import { ErrorComponent } from "../ui-components/ErrorComponent";
import { PollingComponent } from "../ui-components/PollingComponent";

import { generateAdviceNotesByShipDate } from "../utils/despatchFunctionsClient";

// Material-ui imports
import { Alert, LinearProgress, Paper } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Box } from "@mui/system";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const DeliveryPaperworkContent = () => {
  const NO_PAPERWORK = "No paperwork generated";

  const [locationId, setLocationId] = useState(null);
  const [requestedShipDate, setRequestedShipDate] = useState(null);

  const [deliveryPaperworkData, setDeliveryPaperworkData] = useState(null);
  const [deliveryPaperworkWebhookData, setDeliveryPaperworkWebhookData] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const { result, error } = useMsalAuthentication(InteractionType.Redirect, {
    ...loginRequest,
    redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
  });

  useEffect(() => {
    setApiError(null);
    if (!!error) {
      // Error occurred attempting to acquire a token, either handle the error or do nothing
      setLoading(false);

      return;
    }

    if (requestedShipDate !== null) {
      setLoading(true);
      if (result) {
        generateAdviceNotesByShipDate(
          requestedShipDate.format("YYYY-MM-DD"),
          locationId
        )
          .then((response) => {
            setDeliveryPaperworkData(response);
            setLoading(false);
          })
          .catch((e) => {
            setApiError(e);
            setLoading(false);
          });
      }
    }
  }, [error, result, requestedShipDate, locationId]);

  if (error) {
    return <ErrorComponent error={error} />;
  }

  const handlePollingComplete = async (data) => {
    if (data && data.output) {
      setDeliveryPaperworkWebhookData(data.output);

      // only try and download if the PdfUri is not null
      if (data.output.PdfUri) {
        var link = document.createElement("a");
        link.download = `${requestedShipDate.format(
          "YYYY-MM-DD"
        )}-advice-notes`;
        link.href = data.output.PdfUri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      setDeliveryPaperworkWebhookData(NO_PAPERWORK);
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ mt: 4, padding: 4, mr: 4, minWidth: 320 }}>
        <FormControl>
          <FormLabel id="location-radio-buttons-group-label">
            1. Select Location:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="location-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {
              setLocationId(e.target.value);
            }}
          >
            <FormControlLabel value="100" control={<Radio />} label="100" />
            <FormControlLabel value="200" control={<Radio />} label="200" />
            <FormControlLabel value="300" control={<Radio />} label="300" />
          </RadioGroup>
        </FormControl>
      </Paper>
      <Paper elevation={3} sx={{ mt: 4, padding: 4, mr: 4, minWidth: 320 }}>
        <FormLabel id="datepicker-radio-buttons-group-label">
          2. Select Date & Click OK:
        </FormLabel>
        <StaticDatePicker
          value={requestedShipDate}
          onChange={(e) => {
            setApiError(null);
            setDeliveryPaperworkWebhookData(null);
          }}
          onAccept={(e) => {
            setRequestedShipDate(e);
            setDeliveryPaperworkWebhookData(null);
          }}
          format="DD/MM/YYYY"
          sx={{
            ".MuiInputBase-root": {
              borderRadius: "0px!important",
            },
          }}
        />
      </Paper>
      <Paper elevation={3} sx={{ mt: 4, padding: 4, minWidth: 320 }}>
        <FormLabel id="results-radio-buttons-group-label">3. Results</FormLabel>
        {loading ? (
          <>
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                padding: 4,
              }}
            >
              <LinearProgress size={20} />
            </Box>
          </>
        ) : null}
        {apiError ? <Alert severity="error">{apiError.message}</Alert> : null}
        {deliveryPaperworkData ? (
          <>
            <Alert severity="info">
              {`Generating paperwork for ${requestedShipDate.format(
                "DD-MM-YYYY"
              )}  at ${locationId}.`}
            </Alert>
            <PollingComponent
              statusQueryGetUri={deliveryPaperworkData.statusQueryGetUri}
              handlePollingComplete={handlePollingComplete}
            />
          </>
        ) : null}
        {deliveryPaperworkWebhookData ? (
          deliveryPaperworkWebhookData.PdfUri === null &&
          deliveryPaperworkWebhookData.Successful === false ? (
            <Alert severity="error">
              {deliveryPaperworkWebhookData.Message}
            </Alert>
          ) : deliveryPaperworkWebhookData.PdfUri === null &&
            deliveryPaperworkWebhookData.Successful === true ? (
            <Alert severity="info">
              {deliveryPaperworkWebhookData.Message}
            </Alert>
          ) : deliveryPaperworkWebhookData.PdfUri ? (
            <Alert severity="success">
              {deliveryPaperworkWebhookData.Message}
            </Alert>
          ) : (
            <Alert severity="warning">
              This did not complete properly. Please try again, or contact IT.
            </Alert>
          )
        ) : null}
      </Paper>
    </>
  );
};

export function DeliveryPaperwork() {
  return <DeliveryPaperworkContent />;
}
