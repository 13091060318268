import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
// import { AccountPicker } from "./AccountPicker";

import { Link as RouterLink } from "react-router-dom";

export const SignOutButton = () => {
  const { instance } = useMsal();
  const [accountSelectorOpen, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    setAnchorEl(null);
    instance.logoutRedirect();
  };

  // const handleAccountSelection = () => {
  //     setAnchorEl(null);
  //     setOpen(true);
  // }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem component={RouterLink} to="/userClaims" onClose={handleClose}>
          View Claims
        </MenuItem>
        <MenuItem component={RouterLink} to="/profile" onClose={handleClose}>
          View Profile
        </MenuItem>
        <MenuItem onClick={() => handleLogout()} key="logoutRedirect">
          Logout
        </MenuItem>
      </Menu>
      {/* <AccountPicker open={accountSelectorOpen} onClose={handleClose} /> */}
    </div>
  );
};
