import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { Link as RouterLink } from "react-router-dom";

import { Divider, Stack } from "@mui/material";

import { useIsAuthenticated } from "@azure/msal-react";
import { Box } from "@mui/system";

import WaterlineLogo from "../assets/images/waterline-logo.png";

const NavBar = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <div sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar spacing={1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: isAuthenticated ? "space-between" : "flex-end",
              width: "100%",
            }}
          >
            {isAuthenticated ? (
              <Stack direction="row" spacing={1} alignItems="center">                
                <Link
                  component={RouterLink}
                  to="/"
                  color="inherit"
                  variant="h6"
                  underline="none"
                  sx={{ marginRight: "8px!important", marginTop: "10px!important" }}
                >
                  <img alt="" src={WaterlineLogo} width={40} />
                </Link>
                <Link
                  component={RouterLink}
                  to="/deliveryPaperwork"
                  color="inherit"
                  variant="h6"
                  underline="none"
                >
                  Despatch
                </Link>
              </Stack>
            ) : null}

            <Stack direction="row" spacing={1} alignItems="center">
              <WelcomeName />
              <SignInSignOutButton />
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBar;
