// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Sample app imports
import { Loading } from "../ui-components/Loading";
import { ErrorComponent } from "../ui-components/ErrorComponent";

// import { Table } from 'react-bootstrap';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { createClaimsTable } from "../utils/claimUtils";

import "../styles/App.css";

const UserClaimsContent = () => {
  const { instance } = useMsal();

  // useEffect(() => {
  //     var account = instance.getActiveAccount()
  // }, [instance]);

  const account = instance.getActiveAccount();
  debugger;
  const tokenClaims = createClaimsTable(account.idTokenClaims);

  const tableRow = Object.keys(tokenClaims).map((key, index) => {
    return (
      <TableRow key={key}>
        {tokenClaims[key].map((claimItem) => (
          <TableCell key={claimItem}>{claimItem}</TableCell>
        ))}
      </TableRow>
    );
  });
  return (
    <>
      <div className="data-area-div">
        <p>
          See below the claims in your <strong> ID token </strong>. For more
          information, visit:{" "}
          <span>
            <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token">
              docs.microsoft.com
            </a>
          </span>
        </p>
        <div className="data-area-div">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Claim</TableCell>
                  <TableCell align="left">Value</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRow}
                {/* {rows.map((row) => (
                                <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.calories}</TableCell>
                                <TableCell align="right">{row.fat}</TableCell>
                                <TableCell align="right">{row.carbs}</TableCell>
                                <TableCell align="right">{row.protein}</TableCell>
                                </TableRow>
                            ))} */}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export function UserClaims() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <UserClaimsContent />
    </MsalAuthenticationTemplate>
  );
}

// export const IdTokenData = (props) => {
//     const tokenClaims = createClaimsTable(props.idTokenClaims);

//     const tableRow = Object.keys(tokenClaims).map((key, index) => {
//         return (
//             <tr key={key}>
//                 {tokenClaims[key].map((claimItem) => (
//                     <td key={claimItem}>{claimItem}</td>
//                 ))}
//             </tr>
//         );
//     });
//     return (
//         <>
//             <div className="data-area-div">
//                 <p>
//                     See below the claims in your <strong> ID token </strong>. For more information, visit:{' '}
//                     <span>
//                         <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/id-tokens#claims-in-an-id-token">
//                             docs.microsoft.com
//                         </a>
//                     </span>
//                 </p>
//                 <div className="data-area-div">
//                     <Table responsive striped bordered hover>
//                         <thead>
//                             <tr>
//                                 <th>Claim</th>
//                                 <th>Value</th>
//                                 <th>Description</th>
//                             </tr>
//                         </thead>
//                         <tbody>{tableRow}</tbody>
//                     </Table>
//                 </div>
//             </div>
//         </>
//     );
// };
